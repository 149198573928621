import React from "react"
import { graphql } from "gatsby"
import * as styles from '../assets/styles.module.scss'
import { default as Header } from '../pages/common/header'
import { default as Footer } from '../pages/common/footer'
import { default as Contact } from '../pages/homepage/Contact'

const BlogPost = ({ data }) => {
  const { markdownRemark } = data
  const imageSource = markdownRemark.frontmatter.image
  const avatar = markdownRemark.frontmatter.avatar
  const excerpt = markdownRemark.html.replace(/(<([^>]+)>)/ig,"").substring(0,200)

  return (
    <>
        <Header 
            title={markdownRemark.frontmatter.title}
            coverImage={imageSource}
            description={excerpt}
        />
        <section className={styles.BlogPost}>
            <header>
                <h1>{markdownRemark.frontmatter.title}</h1>
                <p>
                    <img alt={markdownRemark.frontmatter.author}
                        style={{width: '50px', height: '50px', borderRadius: '100%', marginRight: '16px'}}
                        src={avatar} />
                    <strong>{markdownRemark.frontmatter.author} </strong> | {markdownRemark.frontmatter.date}
                </p>
                {/* Categories: {markdownRemark.frontmatter.category.join(', ')} | 
                Tags: {markdownRemark.frontmatter.tags.join()}</p> */}
            </header>
            <img src={imageSource} alt={markdownRemark.frontmatter.title} />

            <div className={styles.BlogPostContent}
                    dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        </section>
        <Contact />
        <Footer />
    </>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        featured
        avatar
        category
        tags
        image
      }
    }
  }
`